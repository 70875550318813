import './App.css';
import Main from './components/Layout'
import Login from './components/Login'
import {React, useEffect, useState} from 'react'

function App() {

  const [loginVerify , setLoginVerify] = useState(0)


  function logon(loginVerifyNumber) {

    setLoginVerify(loginVerifyNumber)

  }

  return (
    <div className="App">
{ loginVerify == 0 && <Login logon={logon}></Login>}
{ loginVerify == 1 && <Main logon={logon}></Main>}



    </div>
  );
}

export default App;

import React from 'react';
import './Main.css';
import ImagemSejus from '../assets/img/sejus.png'

const Footer = () => {
  return (
    <footer className="footer">
      <img style={{height:65}}src={ImagemSejus} ></img>
    </footer>
  );
};

export default Footer;

import React, { useState, useEffect } from 'react';
import './Login.css';
import logo from '../assets/img/logo2.png';
import axios from 'axios'; // Importa o axios para fazer requisições HTTP

const Login = ({ logon }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    // Verifica se há um usuário logado no localStorage
    const loggedInUser = localStorage.getItem('username');
    if (loggedInUser) {
      // Se o usuário estiver logado, chama a função logon para redirecionar
      logon(1);
    }
  }, [logon]);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Criptografar a senha no frontend antes de enviar (opcional)
      const encryptedPassword = password; // Supondo que a senha seja criptografada no backend

      const response = await axios.post('https://alertadiarioes.com/apipavir/login.php', {
        username: username,
        password: encryptedPassword
      });

      if (response.data.status === 'success') {
        // Salva o nome de usuário no localStorage
        localStorage.setItem('username', username);
        console.log(response.data.message);
        // Redireciona ou cria uma sessão de login
        logon(1);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Erro ao fazer login:', error);
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <div style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          marginBottom: 30,
          marginLeft: -30,
          letterSpacing: 1
        }}>
          <img src={logo} style={{ height: 65, marginRight: 8 }} alt="Logo" />
          <h2 className="login-title">
            PARLATÓRIO <br />
            VIRTUAL
          </h2>
        </div>
        <form onSubmit={handleLogin}>
          <input
            type="text"
            placeholder="Usuário"
            className="login-input"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="Senha"
            className="login-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit" className="login-button">Entrar</button>
        </form>
      </div>
    </div>
  );
};

export default Login;

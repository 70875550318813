import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Footer from './Footer';
import ImageModal from './ImageModal';
import './Main.css';
import { MdEmail } from "react-icons/md";
import { FaCheck, FaEye } from "react-icons/fa";
import { RiCloseLargeLine } from "react-icons/ri";
import { Document, Packer, Paragraph, Table, TableCell, TableRow, TextRun, HeadingLevel } from "docx";
import InputMask from 'react-input-mask';
import { FaUserShield } from "react-icons/fa6";





// Função para converter data e hora para objeto Date
const parseDateTime = (dateStr, timeStr) => {
  const [day, month, year] = dateStr.split('/').map(Number);
  const [hours, minutes] = timeStr.split(':').map(Number);
  return new Date(year, month - 1, day, hours, minutes);
};

const Layout = ({logon}) => {
  const [data, setData] = useState([]);
  const [dataHorarios, setDataHorarios] = useState([]);
  const [dataLinks, setDataLinks] = useState([]);
  const [dataCadastro, setDataCadastro] = useState('');
  const [horarioCadastro, setHorarioCadastro] = useState('');
  const [dataDiaAtendimento, setDataDiaAtendimento] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailData, setEmailData] = useState({ email: '', message: '' });
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelMessage, setCancelMessage] = useState('');
  const [selectedCancelId, setSelectedCancelId] = useState(null);
  const [pagina , setPagina] = useState(1);
  const [ reload , setReload] = useState(0)
  const [formData, setFormData] = useState([]);
  const username = localStorage.getItem('username');



    const handleLogout = () => {
      // Remove o nome de usuário do localStorage
      localStorage.removeItem('username');
      
      // Chama a função de logout para redirecionar para a página de login
      logon(0);
    };

  const handleInputChange = (e, index, field) => {
    const newFormData = [...formData];
    newFormData[index] = {
        ...newFormData[index],
        [field]: e.target.value
    };
    setFormData(newFormData);
};


  const handleSaveLinks = (index) => {
    const itemData = formData[index] || {};

    fetch('https://alertadiarioes.com/apipavir/api_links.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            id: dataLinks[index].id, // Usando o id do item original
            link1: itemData.link1 || dataLinks[index].link1,
            senha1: itemData.senha1 || dataLinks[index].senha1,
            link2: itemData.link2 || dataLinks[index].link2,
            senha2: itemData.senha2 || dataLinks[index].senha2
        }),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Sucesso:', data);
      })
      .catch(error => {
        console.error('Erro:', error);
      });
};

  const [filters, setFilters] = useState({
    id: '',
    advogado: '',
    oab: '',
    data: '',
    interno: '',
    procuracao: '',
    status: ''
  });



// Função para enviar dados para a API externa
const deleteDia = async (dia) => {
  try {
      // Convertendo os dados para JSON (se necessário)
      const data = JSON.stringify({ desc_dia: dia });

      const response = await axios.post(
        'https://alertadiarioes.com/apipavir/delete_data_atendimento.php',
        { desc_dia: dia },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (response.status === 200 || response.status === 201) {
        setReload(reload+1)
          console.log('Dados enviados:', data);
          console.log('Resposta da API:', response.data);
      } else {
          alert('Ocorreu um erro ao realizar o cadastro. Código de status:', response.status);
          console.error('Erro na resposta:', response.data);
      }
  } catch (error) {
      console.error('Erro ao enviar dados para a API:', error);
      alert('Ocorreu um erro inesperado ao tentar realizar o cadastro.');
  }
};
const deleteHorario = async (desc_horario_cad) => {
  try {
      // Convertendo os dados para JSON (se necessário)
      const data = JSON.stringify({ desc_horario_cad: desc_horario_cad });

      const response = await axios.post(
        'https://alertadiarioes.com/apipavir/delete_horario_atendimento.php',
        { desc_horario_cad: desc_horario_cad },
        { headers: { 'Content-Type': 'application/json' } }
      );
      

      if (response.status === 200 || response.status === 201) {
        setReload(reload+1)
          console.log('Dados enviados:', data);
          console.log('Resposta da API:', response.data);
      } else {
          alert('Ocorreu um erro ao realizar o cadastro. Código de status:', response.status);
          console.error('Erro na resposta:', response.data);
      }
  } catch (error) {
      console.error('Erro ao enviar dados para a API:', error);
      alert('Ocorreu um erro inesperado ao tentar realizar o cadastro.');
  }
};



const handleCadastro = async () => {
  try {
      // Convertendo os dados para JSON (se necessário)
      const data = JSON.stringify({ desc_dia: dataCadastro });
      
      const response = await axios.post(
        'https://alertadiarioes.com/apipavir/api_data_atendimento.php',
        { desc_dia: dataCadastro },
        { headers: { 'Content-Type': 'application/json' } }
      );
      
      if (response.status === 200 || response.status === 201) {
        setReload(reload+1)
        
          console.log('Dados enviados:', data);
          console.log('Resposta da API:', response.data);
          
      } else {
          alert('Ocorreu um erro ao realizar o cadastro. Código de status:', response.status);
          console.error('Erro na resposta:', response.data);
      }
  } catch (error) {
      console.error('Erro ao enviar dados para a API:', error);
      alert('Ocorreu um erro inesperado ao tentar realizar o cadastro.');
  }
};
const handleCadastroHorario = async () => {
  try {
      // Convertendo os dados para JSON (se necessário)
      const data = JSON.stringify({ desc_horario_cad: horarioCadastro });
      
      const response = await axios.post(
        'https://alertadiarioes.com/apipavir/api_horario.php',
        { desc_horario_cad: horarioCadastro },
        { headers: { 'Content-Type': 'application/json' } }
      );
      
      if (response.status === 200 || response.status === 201) {
        setReload(reload+1)
        
          console.log('Dados enviados:', data);
          console.log('Resposta da API:', response.data);
          
      } else {
          alert('Ocorreu um erro ao realizar o cadastro. Código de status:', response.status);
          console.error('Erro na resposta:', response.data);
      }
  } catch (error) {
      console.error('Erro ao enviar dados para a API:', error);
      alert('Ocorreu um erro inesperado ao tentar realizar o cadastro.');
  }
};





  useEffect(() => {
    axios.get('https://alertadiarioes.com/apipavir/get_agendamentos.php')
      .then(response => {
        const dataWithDate = response.data.map(item => ({
          ...item,
          parsedDate: parseDateTime(item.dataAgendada, item.horarioAgendado)
        }));
        setData(dataWithDate);
        setLoading(false);
      })
      .catch(error => {
        
        setLoading(false);
      });
  }, [reload], []);


  useEffect(() => {
    axios.get('https://alertadiarioes.com/apipavir/get_links.php')
      .then(response => {
        const dataWithDate = response.data.map(item => ({
          ...item,
        }));
        setDataLinks(dataWithDate);
        setLoading(false);
      })
      .catch(error => {
        
        setLoading(false);
      });
  }, [reload], []);

  

  useEffect(() => {
    axios.get('https://alertadiarioes.com/apipavir/get_horarios_disponiveis.php')
      .then(response => {
        
        setDataHorarios(response.data);
        setLoading(false);
      })
      .catch(error => {
        
        setLoading(false);
      });
  }, [reload], []);


  useEffect(() => {
    axios.get('https://alertadiarioes.com/apipavir/get_dia_atendimento.php')
      .then(response => {
        console.log('Dados recebidos:', response.data);
        setDataDiaAtendimento(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Erro ao carregar dados', error);
        
        setLoading(false);
      });
}, [reload]); // Verifique se o `reload` é necessário


  useEffect(() => {
    const applyFilters = () => {
      return data
        .filter(item => {
          return (
            (filters.id ? item.id.toString().includes(filters.id) : true) &&
            (filters.advogado ? (item.advogado1 + ' ' + item.advogado2).toLowerCase().includes(filters.advogado.toLowerCase()) : true) &&
            (filters.oab ? (item.oab1 + '-' + item.seccional1 + ' ' + item.oab2 + '-' + item.seccional2).includes(filters.oab) : true) &&
            (filters.data ? (item.dataAgendada + ' às ' + item.horarioAgendado).includes(filters.data) : true) &&
            (filters.interno ? item.nomePreso.toLowerCase().includes(filters.interno.toLowerCase()) : true) &&
            (filters.procuracao ? (item.imagemProcuracao + ' ' + item.imagemProcuracao2).includes(filters.procuracao) : true) &&
            (filters.status ? (item.verificado.toString() === filters.status) : true)
          );
        })
        .sort((a, b) => b.parsedDate - a.parsedDate); // Ordena os dados pela data
    };

    setFilteredData(applyFilters());
  }, [data, filters]);

  const handleVerify = (id) => {
    axios.post('https://alertadiarioes.com/apipavir/update_verificado.php', { id })
      .then(response => {
        if (response.data.status === "success") {
          setData(prevData => prevData.map(item =>
            item.id === id ? { ...item, verificado: 1 } : item
          ));
        } else {
          alert('Erro ao verificar o registro.');
        }
      })
      .catch(error => {
        alert('Erro ao conectar com a API.');
      });
  };

  const handleCancel = () => {
    if (!cancelMessage.trim()) {
      alert('Preencha o campo de mensagem antes de cancelar.');
      return;
    }

    axios.post('https://alertadiarioes.com/apipavir/update_cancelado.php', {
      id: selectedCancelId,
      reason: cancelMessage
    })
      .then(response => {
        if (response.data.status === "success") {
          setData(prevData => prevData.map(item =>
            item.id === selectedCancelId ? { ...item, verificado: 2 } : item
          ));
          setShowCancelModal(false);
          setReload(reload+1)
        } else {
          alert('Erro ao cancelar o registro.');
        }
      })
      .catch(error => {
        alert('Erro ao conectar com a API.');
      });
  };

  const openCancelModal = (id) => {
    setSelectedCancelId(id);
    setCancelMessage('');
    setShowCancelModal(true);
  };

  const closeCancelModal = () => {
    setShowCancelModal(false);
  };

  const openEmailModal = (email) => {
    setEmailData({ email, message: '' });
    setShowEmailModal(true);
  };

  const closeEmailModal = () => {
    setShowEmailModal(false);
  };

  const handleEmailSend = () => {
    axios.post('https://alertadiarioes.com/apipavir/send_email.php', emailData)
      .then(response => {
        alert('Email enviado com sucesso!');
        closeEmailModal();
      })
      .catch(error => {
        alert('Erro ao enviar email');
      });
  };

  const openImageModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  const generateWordDocument = () => {
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              text: "Dados Filtrados",
              heading: HeadingLevel.HEADING_1,
            }),
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({ children: [new Paragraph("Advogado(s)")] }),
                    new TableCell({ children: [new Paragraph("OAB")] }),
                    new TableCell({ children: [new Paragraph("Data")] }),
                    new TableCell({ children: [new Paragraph("Interno")] }),
                    new TableCell({ children: [new Paragraph("Status")] }),
                    new TableCell({ children: [new Paragraph("Motivo")] }),
                  ],
                }),
                ...filteredData.map(item => new TableRow({
                  children: [
                    new TableCell({ children: [new Paragraph(`${item.advogado1} / ${item.advogado2 || ''}`)] }),
                    new TableCell({ children: [new Paragraph(`${item.oab1}-${item.seccional1} ${item.oab2 || ''}-${item.seccional2 || ''}`)] }),
                    new TableCell({ children: [new Paragraph(`${item.dataAgendada} às ${item.horarioAgendado}`)] }),
                    new TableCell({ children: [new Paragraph(item.nomePreso)] }),
                    new TableCell({ children: [new Paragraph(item.verificado == 1 ? 'Atendido' : item.verificado == 2 ? 'Cancelado' : 'Pendente')] }),
                    new TableCell({ children: [new Paragraph(item.motivoCancelado)] }),
                  ],
                })),
              ],
            }),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then(blob => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "dados_filtrados.docx";
      link.click();
    });
  };

  return (
    <div className="layout">
      <div className="sidebar">
    <h3>PARLATÓRIO VIRTUAL</h3>
    <ul>
        <li onClick={() => setPagina(1)}>Agenda</li>
        <li onClick={() => setPagina(2)}>Datas</li>
        <li onClick={() => setPagina(3)}>Horários</li>
        <li onClick={() => setPagina(4)}>Salas</li>
        <li onClick={() => handleLogout()}>Sair</li>
    </ul>
</div>

      <div className="main-content">
        <header className="header">
          <h2>
          { pagina == 1 && 'AGENDAMENTOS'}
          { pagina == 2 && 'DATAS DISPONÍVEIS'}
          { pagina == 3 && 'HORÁRIOS DISPONÍVEIS'}
          { pagina == 4 && 'SALAS DE ATENDIMENTO'}
          { pagina == 4 && 'USUÁRIOS'}
          <div style={{textAlign:'right', fontSize:16, marginBottom:-10, marginRight:15, display:'flex', flexDirection:'row', justifyContent:'right'}}>
          <span>{username} <FaUserShield /></span>
</div>
          </h2>
          
        </header>
        { pagina ==1 && <div className="content">
        

          {/* Filtros */}
          <div className="filters">
            <input
              type="text"
              placeholder="Número"
              value={filters.id}
              onChange={(e) => setFilters({ ...filters, id: e.target.value })}
            />
            <input
              type="text"
              placeholder="Advogado(s)"
              value={filters.advogado}
              onChange={(e) => setFilters({ ...filters, advogado: e.target.value })}
            />
            <input
              type="text"
              placeholder="OAB"
              value={filters.oab}
              onChange={(e) => setFilters({ ...filters, oab: e.target.value })}
            />
            <input
              type="text"
              placeholder="Data e Hora"
              value={filters.data}
              onChange={(e) => setFilters({ ...filters, data: e.target.value })}
            />
            <input
              type="text"
              placeholder="Interno"
              value={filters.interno}
              onChange={(e) => setFilters({ ...filters, interno: e.target.value })}
            />
            <select
              value={filters.status}
              onChange={(e) => setFilters({ ...filters, status: e.target.value })}
            >
              <option value="">Todos</option>
              <option value="0">Pendente</option>
              <option value="1">Verificado</option>
              <option value="2">Cancelado</option>
            </select>
            <button onClick={generateWordDocument}>Gerar Word</button>
          </div>
          {loading && <p>Carregando...</p>}
          {error && <p>{error}</p>}
          {!loading && !error && (
            <table>
              <thead>
                <tr>
                  <th><center>Número</center></th>
                  <th><center>Advogado(s)</center></th>
                  <th><center>OAB</center></th>
                  <th><center>Carteira</center></th>
                  <th><center>Data</center></th>
                  <th><center>Interno</center></th>
                  <th><center>Procuração</center></th>
                  <th><center>Status</center></th>
                  <th><center></center></th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(filteredData)&& filteredData.map((item, index) => (
                  <tr key={item.id}>
                    <td><center>{index+1}</center></td>
                    <td><center>{item.advogado1}{item.advogado2 && <a> /<br /> {item.advogado2}</a>}</center></td>
                    <td><center>{item.oab1}-{item.seccional1}{item.oab2 && <a> / {item.oab2}-{item.seccional2}</a>}</center></td>
                    <td>
                      <center>
                        <div className='button-container2'>
                          <button className="button-act2" onClick={() => openImageModal(`https://alertadiarioes.com/apipavir/img/${item.imagemOab}`)}><FaEye /></button>
                          &nbsp;  {item.advogado2 && <button className="button-act2" onClick={() => openImageModal(`https://alertadiarioes.com/apipavir/img/${item.imagemOab2}`)}><FaEye /></button>}
                        </div>
                      </center>
                    </td>
                    <td style={{fontSize:14}}><center>{item.dataAgendada} <br />às {item.horarioAgendado} ({item.sala})</center></td>
                    <td><center>{item.nomePreso}</center></td>
                    <td>
                      <center>
                        <div className='button-container2'>
                          <button className="button-act2" onClick={() => openImageModal(`https://alertadiarioes.com/apipavir/img/${item.imagemProcuracao}`)}><FaEye /></button>
                          &nbsp;{item.advogado2 && <button className="button-act2" onClick={() => openImageModal(`https://alertadiarioes.com/apipavir/img/${item.imagemProcuracao2}`)}><FaEye /></button>}
                        </div>
                      </center>
                    </td>
                    
                    <td><center>
                      {item.verificado == 0 && <div className="yellow-ball"></div>}
                      {item.verificado == 1 && <div className="green-ball"></div>}
                      {item.verificado == 2 && <div className="red-ball"></div>}
                    </center></td>
                    <td>
                      <div className='button-container'>&nbsp;
                        <a className="button-act" onClick={() => openEmailModal(item.emailadv)}><MdEmail /></a> &nbsp;
                        <a className="button-act" onClick={() => handleVerify(item.id)}><FaCheck /></a> &nbsp;
                        {item.verificado != 2 && <a className="button-act" onClick={() => openCancelModal(item.id)}><RiCloseLargeLine /></a>}
                        {item.verificado == 2 && <a className="button-act"><RiCloseLargeLine /></a>}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          
        </div>}

        {pagina == 2 && (
  <div className="content">
    {/* Filtros */}
    <div className="filters">
      <InputMask
        mask="99/99/9999"
        placeholder="Data (dd/mm/aaaa)"
        value={dataCadastro}
        onChange={(e) => setDataCadastro(e.target.value)}
      >
        {(inputProps) => <input {...inputProps} />}
      </InputMask>

      <button onClick={()=>handleCadastro()}>Cadastrar</button>
    </div>
    {loading && <p>Carregando...</p>}
    {error && <p>{error}</p>}
    {!loading && !error && (
      <table>
        <thead>
          <tr>
            <th><center>Número</center></th>
            <th><center>Dia</center></th>
            <th><center>Excluir</center></th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(dataDiaAtendimento)&& dataDiaAtendimento.map((item, index) => (
            <tr key={item.id}>
              <td><center>{index+1}</center></td>
              <td><center>{item.desc_dia}</center></td>
              <td>
                <div className='button-container'>&nbsp;
                 
                  <a className="button-act" onClick={() => deleteDia(item.desc_dia)}><RiCloseLargeLine /></a>
                
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )}
  </div>
)}
        {pagina == 3 && (
  <div className="content">
    {/* Filtros */}
    <div className="filters">
      <InputMask
        mask="99:99 - 99:99"
        placeholder="exemplo 08:00 - 08:30"
        value={horarioCadastro}
        onChange={(e) => setHorarioCadastro(e.target.value)}
      >
        {(inputProps) => <input {...inputProps} />}
      </InputMask>

      <button onClick={()=>handleCadastroHorario()}>Cadastrar</button>
    </div>
    {loading && <p>Carregando...</p>}
    {error && <p>{error}</p>}
    {!loading && !error && (
      <table>
        <thead>
          <tr>
            <th><center>Número</center></th>
            <th><center>horário</center></th>
            <th><center>Excluir</center></th>
          </tr>
        </thead>
        <tbody>
        {Array.isArray(dataHorarios) && dataHorarios.map((item, index) => (
    <tr key={index}>
        <td><center>{index + 1}</center></td> {/* Índice + 1 para começar a contagem em 1 */}
        <td><center>{item.desc_horario_cad}</center></td>
        <td>
            <div className='button-container'>&nbsp;
                <a className="button-act" onClick={() => deleteHorario(item.desc_horario_cad)}><RiCloseLargeLine /></a>
            </div>
        </td>
    </tr>
))}

        </tbody>
      </table>
    )}
  </div>
)}

{pagina == 4 && (
  <div className="content">
    {/* Filtros */}
   
    {loading && <p>Carregando...</p>}
    {error && <p>{error}</p>}
    {!loading && !error && (
      <table>
        <thead>
          <tr>
            <th><center>Link Sala 1</center></th>
            <th><center>Senha Sala 1</center></th>
            <th><center>Link Sala 2</center></th>
            <th><center>Senha Sala 2</center></th>
            <th><center></center></th>
          </tr>
        </thead>
        <tbody>
  {Array.isArray(dataLinks) && dataLinks.map((item, index) => (
    <tr key={item.id}>
      <td>
        <center>
          <input
            type="text"
            value={formData[index]?.link1 || item.link1}
            onChange={(e) => handleInputChange(e, index, 'link1')}
          />
        </center>
      </td>
      <td>
        <center>
          <input
            type="text"
            value={formData[index]?.senha1 || item.senha1}
            onChange={(e) => handleInputChange(e, index, 'senha1')}
          />
        </center>
      </td>
      <td>
        <center>
          <input
            type="text"
            value={formData[index]?.link2 || item.link2}
            onChange={(e) => handleInputChange(e, index, 'link2')}
          />
        </center>
      </td>
      <td>
        <center>
          <input
            type="text"
            value={formData[index]?.senha2 || item.senha2}
            onChange={(e) => handleInputChange(e, index, 'senha2')}
          />
        </center>
      </td>
      <td>
        <div className="button-container">
          &nbsp;
          <button className='buttonRec' onClick={() => handleSaveLinks(index)}>Gravar</button>
        </div>
      </td>
    </tr>
  ))}
</tbody>
        
      </table>
      
    )}
  </div>
)}


        <Footer />
      </div>
      {selectedImage && <ImageModal imageUrl={selectedImage} onClose={closeImageModal} />}
      {showEmailModal && (
        <div className="email-modal" onClick={closeEmailModal}>
          <div className="email-modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={closeEmailModal}>&times;</span>
            <h2>Enviar Email</h2>
            <p>Enviar para: {emailData.email}</p>
            <textarea
              className="email-modal-textarea"
              value={emailData.message}
              onChange={(e) => setEmailData({ ...emailData, message: e.target.value })}
              placeholder="Digite sua mensagem"
            />
            <button className="email-modal-button" onClick={handleEmailSend}>
              <MdEmail /> Enviar
            </button>
          </div>
        </div>
      )}
      {showCancelModal && (
        <div className="cancel-modal" onClick={closeCancelModal}>
          <div className="cancel-modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={closeCancelModal}>&times;</span>
            <h2>Cancelar Agendamento</h2>
            <textarea
              className="cancel-modal-textarea"
              value={cancelMessage}
              onChange={(e) => setCancelMessage(e.target.value)}
              placeholder="Digite o motivo do cancelamento"
            />
            <div className="cancel-modal-button-container">
              <center><button
                className="cancel-modal-button cancel"
                onClick={handleCancel}
                disabled={!cancelMessage.trim()}
              >
                Cancelar Agendamento
              </button>
              <span className="close" onClick={closeCancelModal}></span></center>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Layout;

import React, { useState, useRef } from 'react';
import './Main.css';

const ImageModal = ({ imageUrl, onClose }) => {
  const [zoom, setZoom] = useState(1);
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);

  const handleZoomIn = () => {
    setZoom(prevZoom => prevZoom + 0.5);
  };

  const handleZoomOut = () => {
    setZoom(prevZoom => (prevZoom > 0.5 ? prevZoom - 0.5 : prevZoom));
  };

  const handleMouseDown = (e) => {
    setDragging(true);
    setStartPosition({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      setPosition({
        x: e.clientX - startPosition.x,
        y: e.clientY - startPosition.y,
      });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  if (!imageUrl) return null;

  return (
    <div className="modal" onClick={onClose}>
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <div className="image-container">
          <img
            src={imageUrl}
            alt="Imagem ampliada"
            ref={imageRef}
            style={{
              transform: `scale(${zoom}) translate(${position.x}px, ${position.y}px)`,
              cursor: dragging ? 'grabbing' : 'grab',
            }}
            className="modal-image"
          />
        </div>
        <div className="zoom-controls">
          <button onClick={handleZoomOut}>-</button>
          <button onClick={handleZoomIn}>+</button>
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
